@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Jost Regular";
  src: local("Jost Regular"), url("../public/fonts/Jost-Regular.ttf");
}

@font-face {
  font-family: "Satoshi Regular";
  src: local("Satoshi Regular"), url("../public/fonts/Satoshi-Regular.ttf");
}

@font-face {
  font-family: "Satoshi Medium";
  src: local("Satoshi Medium"), url("../public/fonts/Satoshi-Medium.ttf");
}

@font-face {
  font-family: "Satoshi Bold";
  src: local("Satoshi Bold"), url("../public/fonts/Satoshi-Bold.ttf");
}

@font-face {
  font-family: "Satoshi Black";
  src: local("Satoshi Black"), url("../public/fonts/Satoshi-Black.ttf");
}

@font-face {
  font-family: "Satoshi Light";
  src: local("Satoshi Light"), url("../public/fonts/Satoshi-Light.ttf");
}

@font-face {
  font-family: "Jost SemiBold";
  src: local("Jost SemiBold"), url("../public/fonts/Jost-SemiBold.ttf");
}

@font-face {
  font-family: "Jost Bold";
  src: local("Jost Bold"), url("../public/fonts/Jost-Bold.ttf");
}

body {
  @apply font-satoshiRegular text-primary;
  background: #f4f7fa;
}

header {
  box-shadow: 0px 3px 13px rgba(115, 115, 115, 0.18);
}

.container {
  --max-width: 1440px;
  --padding: 20px;
  width: min(var(--max-width), 100% - (var(--padding) * 2));
  padding: 10px 40px;
}

.bg-with-shadow {
  background: #ffffff;
  box-shadow: 0px -2px 80px rgba(35, 134, 167, 0.06),
    0px -0.835552px 33.4221px rgba(35, 134, 167, 0.0431313),
    0px -0.446726px 17.869px rgba(35, 134, 167, 0.0357664),
    0px -0.250431px 10.0172px rgba(35, 134, 167, 0.03),
    0px -0.133002px 5.32008px rgba(35, 134, 167, 0.0242336),
    0px -0.0553451px 2.21381px rgba(35, 134, 167, 0.0168687);
  border-radius: 12px;
}

.nav-shadow {
  border-radius: 4px;
  box-shadow: 0px -0.446726px 17.869px rgba(35, 134, 167, 0.0357664),
    0px -0.250431px 10.0172px rgba(35, 134, 167, 0.03),
    0px -0.133002px 5.32008px rgba(35, 134, 167, 0.0242336),
    0px -0.0553451px 2.21381px rgba(35, 134, 167, 0.0168687);
}

.option-inactive {
  color: #3c567e;
  cursor: pointer;
  padding: 10px 16px;
  border-radius: 6px;
}

.option-active {
  background-color: #3c567e;
  color: #ffffff;
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 6px;
}

.package-img-sec {
  width: 338px;

  img {
    border-radius: 12px;
  }
}

.radius-md {
  border-radius: 6px;
}

.even-columns {
  display: grid;
  gap: 1rem;
}

.home-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  line-height: 4em;
}

@media (max-width: 767px) {
  .home-heading {
    line-height: 2em;
  }
}

@media (max-width: 900px) {
  .home-heading {
    line-height: 2em;
  }
}

.healthcare {
  animation: type 6.5s steps(50) infinite;
  width: 36%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 3rem;
  font-weight: 700;
}

.home-banner {
  background-image: url("../public/images/images/bg-slide-1.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.button-color button {
  color: #fff;
  padding: 10px 43px 10px 43px;
  border-radius: 5px;
  background: linear-gradient(104.58deg, #3dade0 -10.13%, #80e4ee 121.6%);
  box-shadow: 0px 4px 8px 4px rgba(53, 136, 154, 0.31);
  border-radius: 12px;
}

@keyframes type {
  0% {
    width: 0;
  }
}

@media (min-width: 50em) {
  .even-columns {
    grid-auto-flow: column;
    grid-auto-columns: 2fr;
  }
}

@media (max-width: 767px) {
  .healthcare {
    width: 68%;
  }

  .package-img-sec {
    width: 100%;
  }

  .about-section {
    width: 100%;
    padding: 0 10px 0 16px;
  }

  .sec-pad {
    padding: 1rem 0;
  }

  .response-flex {
    display: block;
  }

  .response-flex img {
    position: relative;
    border-radius: 0px;
  }

  .home-banner {
    height: 320px;

    &::after {
      height: 300px;
      width: 100%;
      background-size: contain;
    }
  }

  .home-heading {
    width: 100%;
  }

  .how_works {
    p {
      margin: auto;
    }
  }

  .header-logo {
    width: 150px !important;
  }
}

.header-title {
  font-size: 32.8421px;
  line-height: 44px;
}

.footer-txt-opacity {
  opacity: 0.75;
}

.footer-made {
  display: inline-flex;
  align-items: baseline;
  gap: 10px;
}

.quick-links {
  a {
    position: relative;
  }

  a::before {
    content: "";
    position: absolute;
    width: 6.6px;
    height: 6.6px;
    left: -12.62px;
    top: 8px;
    background: #80e4ee;
    transform: rotate(45deg);
  }
}

.contact-form {
  .label {
    @apply font-bold;
  }

  input,
  textarea {
    background: #f1f4f8;
    border-radius: 8px;
  }
}

.book-btn {
  background: linear-gradient(104.58deg, #3dade0 -10.13%, #80e4ee 121.6%);
  box-shadow: 0px 4px 8px 4px rgba(53, 136, 154, 0.31);
  border-radius: 12px;
  padding: 16px 24px;
  color: #ffffff;
  font-size: 13px;
  padding: 13px 53px;
}

.popular-popup-heading {
  position: relative;

  h1 {
    font-weight: bold;
  }

  &:before {
    content: "";
    width: 6px;
    height: 7px;
    position: absolute;
    z-index: 9;
    background-color: #0e1438;
    border-radius: 100%;
    left: -10px;
    top: 10px;
  }
}

.model-container {
  @apply w-[90vw] lg:w-[700px];
  margin: auto;
  outline: none;
}

.how_works {
  h4 {
    &:before {
      position: absolute;
      top: -3px;
      left: -11px;
      color: #141c4c;
      opacity: 0.2;
      font-size: 31px;
    }
  }

  p {
    opacity: 0.65;
    width: 70%;
  }

  .first {
    &:before {
      content: "1";
    }
  }

  .second {
    &:before {
      content: "2";
    }
  }

  .third {
    &:before {
      content: "3";
    }
  }
}

.error_input {
  border: 1px solid red;
}

.help-block {
  color: red;
}

.terms-conditions {
  input[type="checkbox"] {
    -ms-transform: scale(1.5);
    /* IE */
    -moz-transform: scale(1.5);
    /* FF */
    -webkit-transform: scale(1.5);
    /* Safari and Chrome */
    -o-transform: scale(1.5);
    /* Opera */
    padding: 10px;
  }
}

.date-time {
  .MuiInputBase-root {
    background: #f1f4f8 !important;
  }
}

.loading-payment {
  display: flex !important;
}

.slider::-webkit-scrollbar {
  display: none;
}

.radial-gradient {
  background: radial-gradient(
    115.38% 115.38% at 50% 0%,
    #415980 0%,
    #20304b 100%
  );
}

.footer-heading {
  @apply text-base font-bold text-white;
}

.footer-content {
  @apply text-sm font-bold text-white opacity-50;
}

.hr-line {
  border-bottom: 1px solid rgba(60, 87, 126, 0.2);
}

.summary-content {
  @apply text-[1rem] font-bold text-white;
}

.details-content {
  @apply mx-2 border-b border-Dusk py-4 text-sm font-medium text-white opacity-50;
}

.content {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  /* Need a specific value to work */
}

.contact_us_footer_bg {
  background: radial-gradient(
    115.38% 115.38% at 50% 0%,
    #415980 0%,
    #20304b 100%
  );
}

.support_bg {
  background-image: url("../public/images/images/support.svg");
  height: 565px;
  margin: auto;
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 327px) {
    background-image: url("../public/images/images/support_sm.svg");

    height: 643px;
    margin: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media (min-width: 500px) {
    background-image: url("../public/images/images/support_md.svg");
    height: 643px;
    margin: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media (min-width: 936px) {
    background-image: url("../public/images/images/support.svg");
    height: 565px;
    margin: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.map_bg {
  background-image: url("../public/images/images/map.svg");
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 327px) {
    background-image: url("../public/images/images/map_sm.svg");
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media (min-width: 500px) {
    background-image: url("../public/images/images/map_md.svg");
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media (min-width: 936px) {
    background-image: url("../public/images/images/map.svg");
    height: 696px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.gradientGreen {
  background: radial-gradient(
    115.38% 115.38% at 50% 0%,
    #33b0aa 0%,
    #017872 100%
  );
}

.terms_and_cookie_bg {
  background-image: url("../public/images/bg/terms_cookie_bg.svg");
  @apply h-[314px] md:h-[395px] lg:h-[547px];
  background-size: cover;
  background-repeat: no-repeat;
}

.home_lab_test {
  @apply h-[320px] w-[266px] md:h-[232px] md:w-[176px] lg:h-[300px] lg:w-[225px];
  background-color: #1d3433;
}

.home_nursing_care {
  @apply h-[320px] w-[266px] md:h-[232px] md:w-[176px] lg:h-[300px] lg:w-[225px];
  background: radial-gradient(
      115.38% 115.38% at 50% 0%,
      #415980 0%,
      #20304b 100%
    ),
    radial-gradient(115.38% 115.38% at 50% 0%, #669dd9 0%, #365088 100%);
}

.iv_drip {
  @apply h-[320px] w-[266px] md:h-[232px] md:w-[176px] lg:h-[300px] lg:w-[225px];
  background: radial-gradient(
    115.38% 115.38% at 50% 0%,
    #ec8f8f 0%,
    #bf4545 100%
  );
}

.physio {
  @apply h-[320px] w-[266px] md:h-[232px] md:w-[176px] lg:h-[300px] lg:w-[225px];
  background: radial-gradient(
      115.38% 115.38% at 50% 0%,
      #415980 0%,
      #20304b 100%
    ),
    radial-gradient(115.38% 115.38% at 50% 0%, #669dd9 0%, #365088 100%);
}
.rehab {
  @apply h-[320px] w-[266px] md:h-[232px] md:w-[176px] lg:h-[300px] lg:w-[225px];
  background: radial-gradient(
    115.38% 115.38% at 50% 0%,
    #33b0aa 0%,
    #017872 100%
  );
}

.parameters_polygon {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  padding: 10px 25px;
  --btn-raise: 1rem;
  clip-path: polygon(
    var(--btn-raise) 0%,
    calc(100% - var(--btn-raise)) 0%,
    100% 50%,
    calc(100% - var(--btn-raise)) 100%,
    var(--btn-raise) 100%,
    0 50%
  );
  background-color: #51c1bc;
  border-radius: 6px;
  text-transform: uppercase;
}

.packages_bg {
  background-image: url("../public/images/bg/package_bg.svg");
  @apply h-[500px] md:h-[442px] lg:h-[550px];
  background-size: cover;
  background-repeat: no-repeat;
}

.banner {
  background-image: url("../public/images/bg/banner.svg");
  @apply h-[260px] md:h-[260px] lg:h-[350px];
  background-size: cover;
  background-repeat: no-repeat;
}

.debug {
  @apply border border-red-800;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: rgba(128, 128, 128, 0.1);
  @apply opacity-20;
  border: 1px solid rgba(174, 162, 162, 0.596) F4F8;
  border-radius: 10px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  width: 10px;
  height: 10px;
  cursor: pointer;
  background: #3c567e;
  border-radius: 10px;
}

.core-line {
  max-width: 100% !important;
}

.bg-leader {
  background-repeat: no-repeat !important;
  background-image: url("../public/images/images/leader_photo.svg");
}

.bg-leader-md {
  background-repeat: no-repeat !important;
  background-image: url("../public/images/images/leader_md.svg");
}

.bg-mission {
  background-repeat: no-repeat !important;
  background-image: url("../public/images/images/about_mission_bg.svg");
}

.default-size {
  @apply mx-auto max-w-[1440px] p-4 py-8 sm:p-12;
}

.swiper-slide {
  @apply m-auto;
}

@media (max-width: 1920px) and (min-width: 1024px) {
  .gridscroll {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 1024px) and (min-width: 640px) {
  .gridscroll {
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    overflow-x: scroll;
  }
}

@media (max-width: 767px) and (min-width: 320px) {
  .gridscroll {
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-auto-flow: column;
    overflow-x: scroll;
  }
}

.list_package_info {
  list-style-image: url("../public/images/shapes/listBullet.svg");
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.date-range-picker {
  .custom-label {
    color: #6a6a78;
    position: relative;
    top: 12px;
  }

  .toggle-label {
    transform: translateY(-15px);
    font-size: 12px;
  }
}

.react-datepicker-wrapper input {
  border: none !important;
  color: #141c4c;
  background-color: transparent;
  outline: none;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0 !important;
}

.li {
  list-style-type: disc;
}

.cancel-btn {
  border-radius: 8px;
  border: 1px solid #ef4949;
  font-size: 12px;
  color: #ef4949;
  padding: 8px 22px;
}

.keyFeatures {
  box-shadow: 0px 33px 80px rgba(35, 134, 167, 0.11),
    0px 13.7866px 33.4221px rgba(35, 134, 167, 0.079074),
    0px 7.37098px 17.869px rgba(35, 134, 167, 0.0655718),
    0px 4.13211px 10.0172px rgba(35, 134, 167, 0.055),
    0px 2.19453px 5.32008px rgba(35, 134, 167, 0.0444282),
    0px 0.913195px 2.21381px rgba(35, 134, 167, 0.030926);
}

.testParameter {
  box-shadow: 0px -0.835552px 33.4221px rgba(35, 134, 167, 0.0431313),
    0px -0.446726px 17.869px rgba(35, 134, 167, 0.0357664),
    0px -0.250431px 10.0172px rgba(35, 134, 167, 0.03),
    0px -0.133002px 5.32008px rgba(35, 134, 167, 0.0242336),
    0px -0.0553451px 2.21381px rgba(35, 134, 167, 0.0168687);
}

.orders_bg {
  background: none;
}

@media (min-width: 1024px) {
  .orders_bg {
    width: 100%;
    height: 100vh;
    background: linear-gradient(
      to right,
      white 0%,
      white 50%,
      #ebeef2 50%,
      #ebeef2 100%
    );
  }
}

.memberSelect {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.partner-feedback-slider {
  @apply pb-72 pt-4 sm:pb-56 sm:pt-10 md:pb-40;
}

.testSlider,
.leaderSlider,
.team-members,
.packageSlider {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
  /* background: rgba(66, 91, 131, 0.7) !important;*/
}

.dropShadow {
  filter: drop-shadow(0px -2px 80px rgba(35, 134, 167, 0.06))
    drop-shadow(0px -0.835552px 33.4221px rgba(35, 134, 167, 0.0431313))
    drop-shadow(0px -0.446726px 17.869px rgba(35, 134, 167, 0.0357664))
    drop-shadow(0px -0.250431px 10.0172px rgba(35, 134, 167, 0.03))
    drop-shadow(0px -0.133002px 5.32008px rgba(35, 134, 167, 0.0242336))
    drop-shadow(0px -0.0553451px 2.21381px rgba(35, 134, 167, 0.0168687));
}

.paymentBtnShadow {
  box-shadow: 0px -2px 80px rgba(35, 134, 167, 0.06),
    0px -0.835552px 33.4221px rgba(35, 134, 167, 0.0431313),
    0px -0.446726px 17.869px rgba(35, 134, 167, 0.0357664),
    0px -0.250431px 10.0172px rgba(35, 134, 167, 0.03),
    0px -0.133002px 5.32008px rgba(35, 134, 167, 0.0242336),
    0px -0.0553451px 2.21381px rgba(35, 134, 167, 0.0168687);
}

.packageShadow {
  box-shadow: 0px 26.5205px 64.2922px rgba(35, 134, 167, 0.11),
    0px 11.0797px 26.8598px rgba(35, 134, 167, 0.079074),
    0px 5.92371px 14.3605px rgba(35, 134, 167, 0.0655718),
    0px 3.32078px 8.05039px rgba(35, 134, 167, 0.055),
    0px 1.76364px 4.2755px rgba(35, 134, 167, 0.0444282),
    0px 0.733892px 1.77913px rgba(35, 134, 167, 0.030926);
}

.inputArea {
  filter: drop-shadow(0px -2px 80px rgba(35, 134, 167, 0.06))
    drop-shadow(0px -0.835552px 33.4221px rgba(35, 134, 167, 0.0431313))
    drop-shadow(0px -0.446726px 17.869px rgba(35, 134, 167, 0.0357664))
    drop-shadow(0px -0.250431px 10.0172px rgba(35, 134, 167, 0.03))
    drop-shadow(0px -0.133002px 5.32008px rgba(35, 134, 167, 0.0242336))
    drop-shadow(0px -0.0553451px 2.21381px rgba(35, 134, 167, 0.0168687));
}

.videobg {
  background-repeat: no-repeat !important;
  background-image: url("../public/images/images/videothumbnail.svg");
}

.rec-carousel-item:focus {
  outline: none;
}

button.rec-dot {
  background-color: #3c567e;
  scale: 0.55;
}

.rec.rec-arrow:disabled {
  visibility: hidden;
}

button.rec-dot:focus {
  background-color: #3c567e;
}

button.rec-dot:active {
  background-color: #3c567e;
}

.large-text {
  display: inline-block;
  text-overflow: ellipsis;
  width: calc(80%);
  white-space: nowrap;
  overflow: hidden;
}

.MuiSlider-thumb {
  color: rgba(216, 221, 229, 1);
}

.MuiSlider-rail {
  color: rgba(60, 87, 126, 0.2);
}

.MuiSlider-track {
  color: #3c567e;
}

.pagination-disable {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.customVerticalCarousel::-webkit-scrollbar {
  width: 12px;
  display: none;
}

.swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  background-color: #d5e4e9;
  width: 75px;
  height: 8px;
  border-radius: 8px;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #3c567e;
  width: 75px;
  height: 9px;
  border-radius: 8px;
}

.carousel-container {
  width: 100%;
  margin: auto;
}

.servicesItem {
  padding-right: 40px;
}

.popularItem {
  padding-right: 40px;
}

.popular-packages-container {
  width: 100%;
  @apply h-[50em] md:h-[40em] lg:h-[40em];
  margin: auto;
}


